<!-- 认识微微定组 -->
<template>
  <div class="meetwwdgroup">
    <div class="head">
      <h6 class="h6">
        <span class="sp">COMPANY PROFILE</span>
        <p>企业实力</p>
        <span class="sp1"></span>
      </h6>
    </div>
    <div class="list">
      <div class="item" v-for='item,index in list' :key='index'>
        <h6 class="h6">{{item.title}}</h6>
        <svg class="icon" v-html='item.icon'></svg>
        <img class="img" :src="item.bg">
        <p class="p">{{item.msg}}</p>
      </div>
    </div>
    <div class="list1">
      <div class="item">
        <p class="p">100万</p>
        <p class="p1">订单数突破100万单</p>
        <img class="img" src="../../assets/img/meetwwdgroup/group.png">
      </div>
      <div class="item">
        <p class="p">亿元</p>
        <p class="p1">2018年度销售额突破亿元</p>
        <img  class="img" src="../../assets/img/meetwwdgroup/group1.png">
      </div>
      <div class="item">
        <p class="p">百家</p>
        <p class="p1">服务过上百家中小企业</p>
        <img  class="img" src="../../assets/img/meetwwdgroup/group.png">
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'meetwwdgroup',
  props: {
    msg: String
  },
  data(){
    return {
      list:[
        {title:'大定制',msg:'投资千万IT团队建设，建立强大的数据中心，自主研发集订单流、物流、文件流无缝对接的“全自动化“ERP运营管理系统，实现下单自动化，可视化，订单实时跟踪;',bg:require('../../assets/img/meetwwdgroup/img.jpg'),icon:'<use xlink:href="#icondadingzhi_1"></use>'},
        {title:'大规模',msg:'先后在全国建立直营、专营、合作标准厂房50000多平，覆盖全国40多家生产供应基地，在全国范围内可实现单天千万产量就近生产发货;',bg:require('../../assets/img/meetwwdgroup/img1.png'),icon:'<use xlink:href="#icondaguimo1"></use>'},
        {title:'集中化',msg:'整合行业采销资源，化整为零，降低企业采购生产成本，集中安排客服、物流、原料、采购及生产，提供一站式标准化产品定制，满足中小微企业小批量定制需求。',bg:require('../../assets/img/meetwwdgroup/img2.png'),icon:'<use xlink:href="#iconjizhonghua"></use>'},
        ]
    }
  },
  methods:{
  },
  mounted(){
  }
}
</script>
<style  lang="scss" scoped>
.meetwwdgroup {
  width: 100%;background: #fff;padding-top:130px;
  .head {
    width: 1200px;margin:0 auto;
    .h6 {
      position: relative;width: 450px;height: 150px;
      p {
        font-size: 32px;position: relative;z-index: 2;letter-spacing: 5px;margin-top:10px;
      }
      .sp {
        display: block;color:#dcdcdc;font-size: 42px;
      }
      .sp1 {
        display: block;border:26px solid #f7f7f7;width: 33px;height: 33px;border-radius: 50%;left:100px;top:55px;position: absolute;z-index: 1;
      }
    }
  }
  .list {
    width: 1200px;margin:0 auto;zoom:1;margin-top:30px;
    .item {
      margin-right: 30px;width: 380px;float:left;height: 250px;position: relative;
      .img {
        position: absolute;bottom:0px;left:0px;
      }
      .p {
        position: absolute;z-index: 1px;
      }
      .icon {
        position: absolute;width: 60px;height: 60px;position: absolute;left:45px;top:5px;z-index: 2;
      }
      .h6 {
        font-size: 24px;color:#fff;margin-top:80px;position: relative;z-index: 2;padding-left:15px;
      }
      .p {
        padding:0px 15px;color:#fff;font-size: 16px;margin-top:12px;line-height: 24px;
      }
      &:last-child {
        margin-right: 0px;
      }
    }
    &:after {
      content: '';display: block;clear:both;
    }
  }
  .list1 {
    zoom:1;
    .item {
      height: 300px;width: 33.33%;float:left;margin-top:80px;position: relative;
      .img {
        position: absolute;top:45px;left:calc((100% - 242px)/2);
      }
      .p {
        color:#333;font-size: 36px;margin-top:100px;text-align: center;
      }
      .p1 {
        color:#333;font-size: 18px;margin-top:70px;text-align: center;position: relative;
        &:after {
          content: '';display: block;position: absolute;width: 48px;height: 4px;background: #333;top:40px;left:calc((100% - 48px)/2);
        }
        &:before {
          content: '';display: block;position: absolute;width: 30px;height: 4px;background: #333;top:47px;left:calc((100% - 30px)/2);
        }
      }
      &:nth-child(1) {
        background: #333;
        .p,.p1 {
          color:#fff;
          &:after {
            background: #fff;
          }
          &:before {
            background: #fff;
          }
        }
      }
      &:nth-child(2) {
        background: #f2f2f2;
      }
      &:nth-child(3) {
        background: #333;
        .p,.p1 {
          color:#fff;
          &:after {
            background: #fff;
          }
          &:before {
            background: #fff;
          }
        }
      }
    }
    &:after {
      content: '';display: block;clear:both;
    }
  }
}
</style>
