<!-- 认识微微定组3 -->
<template>
  <div class="meetwwdgroup3">
    <div class="head">
      <h6 class="h6">
        <span class="sp">COMPANY PROFILE</span>
        <p>商业模式</p>
        <span class="sp1"></span>
      </h6>
    </div>
    <div class="center">
      <div class="box">
        <div class="bg">
          <div class="lt">
            <p class="p">C</p>
            <p class="p1"><span>客户</span></p>
          </div>
          <div class="lt1">
            <p class="p">客户提需求</p>
            <p class="p1">(互联网+)</p>
            <p class="p2">服务跟进</p>
          </div>
          <div class="ct">
            <div class="cts">
              <div class="ctss">
                平台
              </div>
            </div>
            <span class="hr1"></span>
            <span class="hr1"></span>
            <span class="hr1"></span>
            <!-- 5 -->
            <span class="hr"></span>
            <span class="hr"></span>
            <span class="hr"></span>
            <span class="hr"></span>
            <span class="hr"></span>
            <span class="hr"></span>
            <span class="hr"></span>
            <span class="hr"></span>
            <!-- 13 -->
            <span class="sp">设计服务</span>
            <span class="sp">生产服务</span>
            <span class="sp">物流服务</span>
            <!-- 16 -->
            <span class="sp1">客服咨询</span>
            <span class="sp1">需求交互</span>
            <span class="sp1">个性设计</span>
            <span class="sp1">智能设计</span>
            <span class="sp1">柔性定制</span>
            <span class="sp1">精准营销</span>
            <span class="sp1">数据中心</span>
            <span class="sp1">品牌中心</span>
          </div>
          <div class="rt1">
            <p class="p">数据直接发送工厂</p>
            <p class="p1">(互联网+)</p>
            <p class="p2">生产数据对接</p>
          </div>
          <div class="rt">
            <p class="p">M</p>
            <p class="p1"><span>工厂</span></p>
          </div>
        </div>
        <div class="infos">
          <p class="p">C2M模式（即客户对工厂，C代表消费者，M代表工厂）用户到工厂直连，去除所有中间流通加价环节，以“大牌品质，工厂价格”为用户提供个性化定制商品。</p>
          <p class="p">“C2M大定制”基于互联网、大数据、人工智能以及通过生产线的自动化、定制化、节能化、柔性化，运用智能商品订单管理系统随时交换数据，按照客户订单要求，设定供应商和生产工序，最终生产出个性化产品的定制模式。</p>
          <p class="p">消费者直接通过平台下单，工厂接收消费者的个性化需求订单，然后根据需求设计、采购、生产、发货。通过订单管理智能化，订单流程可视化等智能化管理为消费者服务。</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'meetwwdgroup3',
  props: {
    msg: String
  },
  data(){
    return {
    }
  },
  methods:{
  },
  mounted(){
  }
}
</script>
<style  lang="scss" scoped>
.meetwwdgroup3 {
  width: 100%;background: #fff;padding-top:80px;
  .head {
    width: 1200px;margin:0 auto;
    .h6 {
      position: relative;height: 150px;text-align:left;
      p {
        font-size: 32px;position: relative;z-index: 2;letter-spacing: 5px;margin-top:10px;
      }
      .sp {
        display: block;color:#dcdcdc;font-size: 42px;
      }
      .sp1 {
        display: block;border:26px solid #f7f7f7;width: 33px;height: 33px;border-radius: 50%;left:100px;top:55px;position: absolute;z-index: 1;
      }
    }
  }
  .center {
    width: 100%;overflow: hidden;margin-top:20px;margin-top:40px;
    .box {
      width: 1200px;margin:0 auto;
      .bg {
        width: 1380px;height: 450px;margin-left:-90px;position: relative;margin-top:-20px;
        .lt {
          width: 158px;height: 158px;background:url('../../assets/img/meetwwdgroup/group5.png') center center no-repeat;position: absolute;top:150px;left:0px;
          .p {
            font-size: 36px;color:#006de8;font-weight: bold;background: #fff;width: 58px;height: 58px;line-height: 58px;text-align: center;border-radius: 50%;position: absolute;top:-35px;left:55px;
          }
          .p1 {
            width: 105px;height: 105px;background: rgba(255,255,255,0.2);border-radius: 50%;position: absolute;top:30px;left:27px;
            span {
              display: block;width: 58px;height: 58px;background: #fff;border-radius: 50%;position: absolute;top:25px;left:25px;line-height: 58px;text-align: center;font-size: 18px;color:#006de8;font-weight: bold;
            }
          }
        }
        .lt1 {
          background:url('../../assets/img/meetwwdgroup/group7.png') center center no-repeat;width: 375px;height: 130px;top:270px;left:138px;position: absolute;z-index: 1;
          .p {
            text-align: center;font-size: 18px;color:#333;margin-top:30px;
          }
          .p1 {
            text-align: center;color:#006de8;font-size: 18px;margin-top:5px;
          }
          .p2 {
            margin-top:25px;text-align: center;font-size: 18px;color:#333;
          }
        }
        .rt {
          width: 158px;height: 158px;background:url('../../assets/img/meetwwdgroup/group6.png') center center no-repeat;position: absolute;top:95px;right:0px;
          .p {
            font-size: 36px;color:#006de8;font-weight: bold;background: #fff;width: 58px;height: 58px;line-height: 58px;text-align: center;border-radius: 50%;position: absolute;top:-35px;left:55px;
          }
          .p1 {
            width: 105px;height: 105px;background: rgba(255,255,255,0.2);border-radius: 50%;position: absolute;top:30px;left:27px;
            span {
              display: block;width: 58px;height: 58px;background: #fff;border-radius: 50%;position: absolute;top:25px;left:25px;line-height: 58px;text-align: center;font-size: 18px;color:#006de8;font-weight: bold;
            }
          }
        }
        .rt1 {
          background:url('../../assets/img/meetwwdgroup/group8.png') center bottom no-repeat;width: 404px;height: 150px;top:15px;right:140px;position: absolute;z-index: 1;
          .p {
            text-align: center;font-size: 18px;color:#333;margin-top:0px;
          }
          .p1 {
            text-align: center;color:#006de8;font-size: 18px;margin-top:5px;
          }
          .p2 {
            margin-top:25px;text-align: center;font-size: 18px;color:#333;
          }
        }
        .ct {
          width: 342px;height: 342px;background: #4a78ff;border-radius: 50%;border:22px solid #eef2ff;z-index: 2;position: absolute;top:40px;left:480px;
          .cts {
            width: 160px;height: 160px;background: #d1e7ff;border:15px solid #fff;border-radius: 50%;position: absolute;top:75px;left:75px;
            .ctss {
              width: 53px;height: 53px;background: #0078ff;border:5px solid #fff;border-radius: 50%;position: absolute;top:49px;left:49px;text-align:center;line-height: 53px;font-size: 20px;color:#fff;
            }
          }
          .hr1 {
            width: 4px;height: 50px;background: #fff;position: absolute;
            &:nth-child(2) {
              top:90px;left:168px;
            }
            &:nth-child(3) {
              top:175px;left:216px;transform: rotate(120deg);
            }
            &:nth-child(4) {
              top:175px;left:120px;transform: rotate(-120deg);
            }
          }
          .sp {
            font-size: 16px;color:#3167ff;position: absolute;
            &:nth-child(13){
              top:133px;left:97px;transform: rotate(-55deg);
            }
            &:nth-child(14){
              top:136px;left:181px;transform: rotate(55deg);
            }
            &:nth-child(15){
              top:208px;left:140px;
            }
          }
          .hr {
            width: 4px;height: 100px;background: #fff;position: absolute;
            &:nth-child(5) {
              left:168px;top:-22px;
            }
            &:nth-child(6) {
              left:271px;top:19px;transform: rotate(45deg);
            }
            &:nth-child(7) {
              left:312px;top:115px;transform: rotate(90deg);
            }
            &:nth-child(8) {
              left:278px;top:213px;transform: rotate(135deg);
            }
            &:nth-child(9) {
              left:168px;top:264px;
            }
            &:nth-child(10) {
               left:58px;top:213px;transform: rotate(-135deg);
            }
            &:nth-child(11) {
              left:25px;top:115px;transform: rotate(90deg);
            }
            &:nth-child(12) {
              left:65px;top:19px;transform: rotate(-45deg);
            }
          }
          .sp1 {
            font-size: 22px;color:#fff;width: 50px;display: block;position: absolute;
            &:nth-child(16) {
              top:89px;left:27px;
            }
            &:nth-child(17) {
              top:20px;left:100px;
            }
            &:nth-child(18) {
              top:21px;left:199px;
            }
            &:nth-child(19) {
              top:89px;left:270px;
            }
            &:nth-child(20) {
              top:175px;left:270px;
            }
            &:nth-child(21) {
              top:258px;left:208px;
            }
            &:nth-child(22) {
              top:258px;left:93px;
            }
            &:nth-child(23) {
              top:175px;left:27px;
            }
          }
        }
      }
      .infos {
        margin-top:50px;
        .p {
          text-indent: 35px;color:#666666;line-height: 26px;
          &:nth-child(2) {
            text-indent: 25px;
          }
        }
      }
    }
  }
}
</style>
