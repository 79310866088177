<!-- 认识微微定 -->
<template>
  <div class="meetwwd">
    <meetwwdcarousel></meetwwdcarousel>
    <meetwwdgroup></meetwwdgroup>
    <meetwwdgroup1></meetwwdgroup1>
    <meetwwdgroup2></meetwwdgroup2>
    <meetwwdgroup3></meetwwdgroup3>
    <meetwwdgroup4></meetwwdgroup4>
    <meetwwdgroup5></meetwwdgroup5>
    <meetwwdgroup6></meetwwdgroup6>
  </div>
</template>
<script>
import meetwwdcarousel from '@/components/meetwwdcarousel/meetwwdcarousel.vue';//认识微微定头部轮播
import meetwwdgroup from '@/components/meetwwdgroup/meetwwdgroup.vue';//认识微微定组
import meetwwdgroup1 from '@/components/meetwwdgroup1/meetwwdgroup1.vue';//认识微微定组1
import meetwwdgroup2 from '@/components/meetwwdgroup2/meetwwdgroup2.vue';//认识微微定组2
import meetwwdgroup3 from '@/components/meetwwdgroup3/meetwwdgroup3.vue';//认识微微定组3
import meetwwdgroup4 from '@/components/meetwwdgroup4/meetwwdgroup4.vue';//认识微微定组4
import meetwwdgroup5 from '@/components/meetwwdgroup5/meetwwdgroup5.vue';//认识微微定组5
import meetwwdgroup6 from '@/components/meetwwdgroup6/meetwwdgroup6.vue';//认识微微定组6
export default {
  name: 'meetwwd',
  components:{
    meetwwdcarousel,
    meetwwdgroup,
    meetwwdgroup1,
    meetwwdgroup2,
    meetwwdgroup3,
    meetwwdgroup4,
    meetwwdgroup5,
    meetwwdgroup6
  },
  metaInfo() {
    return {
      // title:this.htmltitle,
      title:JSON.parse(window.sessionStorage.getItem('localseo')).SeoTitle?JSON.parse(window.sessionStorage.getItem('localseo')).SeoTitle:JSON.parse(window.sessionStorage.getItem('commonseo')).SeoTitle,
    meta: [
      {
        name: "description",
          content:JSON.parse(window.sessionStorage.getItem('localseo')).SeoDescrition?JSON.parse(window.sessionStorage.getItem('commonseo')).SeoDescrition:JSON.parse(window.sessionStorage.getItem('commonseo')).SeoDescrition
      },
      {
        name: "Keywords",
          content:JSON.parse(window.sessionStorage.getItem('localseo')).SeoKey?JSON.parse(window.sessionStorage.getItem('commonseo')).SeoKey:JSON.parse(window.sessionStorage.getItem('commonseo')).SeoKey
      }
    ]
    }
  },
  data(){
    return {
      htmltitle:'认识微微定-微微定',
    }
  },
  props: {
    msg: String
  },
  methods:{
  },
  mounted(){
    this.$nextTick(()=>{
      // 加载
      var _this = this;
      let _pomise = this.$store.state.headPomise
      this._reduce([_pomise.b])
      .then(()=> {
        if(window.sessionStorage.getItem('isReadFirst')){
            window.sessionStorage.removeItem('isReadFirst')
            // this.$store.dispatch("cmtCap",true);//显示隐藏
            $("#app").css('opacity',1)
        }
        window.scrollTo(0,0);//刷新置顶
        console.log('头部加载完成')
        //this._NProgress.done();//关闭加载动画
        let _footPomiseA = this.$store.state.footPomiseA
        let _footPomiseB = this.$store.state.footPomiseB
        this._reduce([_footPomiseA,_footPomiseB])
        .then(()=> {
          console.log('内容队列加载')
        })
        .catch(function(e) {
            console.log(e)
        })
      })
      .catch(function(e) {
          console.log(e)
      })
      // end
    })
  }
}
</script>
<style  lang="scss" scoped>
.meetwwd {
}
</style>
