<!-- 认识微微定组6 -->
<template>
  <div class="meetwwdgroup6">
    <div class="head">
      <h6 class="h6">
        <span class="sp">DEVELOPMENT HISTORY</span>
        <p class="p">团队实力</p>
      </h6>
    </div>
    <div class="center">
      <div class="box">
        <div class="bg">
          <div class="imglist">
            <div class="item">
              研发中心
            </div>
            <div class="item">
              设计中心
            </div>
            <div class="item">
              供应链中心
            </div>
            <div class="item">
              品牌中心
            </div>
            <div class="item">
              客服中心
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'meetwwdgroup6',
  props: {
    msg: String
  },
  data(){
    return {
    }
  },
  methods:{
  },
  mounted(){
  }
}
</script>
<style  lang="scss" scoped>
.meetwwdgroup6 {
  width: 100%;background: #fff;padding-top:60px;
  .head {
    width: 1200px;margin:0 auto;z-index: 9;
    .h6 {
      position: relative;height: 40px;
      .p {
        font-size: 32px;position: relative;z-index: 2;letter-spacing: 5px;text-align: center;color:#333;
        &:after {
          content: "";display: block;position: absolute;width: 60px;height: 5px;background: #f1f1f1;left:calc((100% - 60px)/2);bottom:-20px;
        }
      }
      .sp {
        display: block;color:#dcdcdc;font-size: 42px;text-align: center;position: relative;z-index: 5;
      }
    }
  }
  .center {
    width: 100%;overflow: hidden;margin-top:20px;margin-top:-120px;
    .box {
      width: 1200px;margin:0 auto;
      .bg {
        // background: url('../../assets/img/meetwwdgroup/group10.png') 0px 0px no-repeat;
        width: 1920px;height: 660px;margin-left:-360px;position: relative;
        .imglist {
          width: 1200px;margin:0 auto;padding-top:300px;
          .item {
            width: 214px;height: 214px;float:left;margin-right: 32.5px;text-align: center;text-align: center;line-height: 214px;color:#fff;font-size: 26px;font-weight: bold;letter-spacing: 2px;
            &:nth-child(1) {
              background: url('../../assets/img/meetwwdgroup/group11.png');
            }
            &:nth-child(2) {
              background: url('../../assets/img/meetwwdgroup/group12.png');
            }
            &:nth-child(3) {
              background: url('../../assets/img/meetwwdgroup/group13.png');
            }
            &:nth-child(4) {
              background: url('../../assets/img/meetwwdgroup/group14.png');
            }
            &:nth-child(5) {
              background: url('../../assets/img/meetwwdgroup/group15.png');
            }
            &:last-child {
              margin-right: 0px;
            }
          }
        }
      }
    }
  }
}
</style>
