<!-- 认识微微定组1 -->
<template>
  <div class="meetwwdgroup1">
    <div class="head">
      <h6 class="h6">
        <span class="sp">COMPANY PROFILE</span>
        <p>企业优势</p>
        <span class="sp1"></span>
      </h6>
    </div>
    <div class="center">
      <div class="box">
        <div class="bg">
          <div class="infos">
            <div class="item">
              <h6 class="h5"><span>品质好</span></h6>
              <p class="p">好设备：全球现金的以色列四色高清数码印刷设备</p>
              <p class="p">好原料：对接全国优质原料供应商，确保质量</p>
              <p class="p">好工艺：拥有数百名经验丰富的印刷工程师</p>
            </div>
            <div class="item">
              <h6 class="h5"><span>服务优</span></h6>
              <p class="p">没人能看低，一箱起订，支持小批量定制</p>
              <p class="p">200多人设计师团队，一对一专属服务</p>
              <p class="p">全国工厂就近发货，大部分地区包邮</p>
            </div>
            <div class="item">
              <h6 class="h5"><span>省心定</span></h6>
              <p class="p">省心：快速响应，最快2小时出图</p>
              <p class="p">省事：免费设计，生产物流一战服务</p>
              <p class="p">省钱：工厂直供，价格统一透明</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'meetwwdgroup1',
  props: {
    msg: String
  },
  data(){
    return {
    }
  },
  methods:{
  },
  mounted(){
  }
}
</script>
<style  lang="scss" scoped>
.meetwwdgroup1 {
  width: 100%;background: #fff;padding-top:130px;
  .head {
    width: 1200px;margin:0 auto;
    .h6 {
      position: relative;height: 150px;text-align:right;
      p {
        font-size: 32px;position: relative;z-index: 2;letter-spacing: 5px;margin-top:10px;
      }
      .sp {
        display: block;color:#dcdcdc;font-size: 42px;
      }
      .sp1 {
        display: block;border:26px solid #f7f7f7;width: 33px;height: 33px;border-radius: 50%;right:100px;top:55px;position: absolute;z-index: 1;
      }
    }
  }
  .center {
    width: 100%;overflow: hidden;margin-top:20px;
    .box {
      width: 1200px;margin:0 auto;
      .bg {
        width: 1920px;height: 698px;background: url('../../assets/img/meetwwdgroup/group2.png') 0px 0px no-repeat;margin-left:-360px;
        .infos {
          float:right;margin-right: 520px;margin-top:65px;
          .item {
            border-bottom:1px dashed #f5f5f5;padding-bottom:25px;margin-bottom:15px;
            .h5 {
              letter-spacing: 3px;position: relative;height: 60px;line-height: 60px;
              span {
                position: relative;z-index: 2;font-size: 20px;
              }
              &:after {
                content: '';display: block;position: absolute;width: 45px;height: 45px;background: #f7f7f7;left:-18px;top:8px;border-radius: 50%;z-index: 1;
              }
            }
            .p {
              color:#666;font-size: 14px;margin-bottom:2px;
            }
            &:last-child {
              border-bottom:0px;
            }
          }
        }
      }
    }
  }
}
</style>
