<!-- 认识微微定组2 -->
<template>
  <div class="meetwwdgroup2">
    <div class="head">
      <h6 class="h6">
        <span class="sp">COMPANY PROFILE</span>
        <p>公司简介</p>
        <span class="sp1"></span>
      </h6>
    </div>
    <div class="center">
      <div class="box">
        <div class="bg">
          <img class="img" src="../../assets/img/meetwwdgroup/group4.png">
          <div class="infos">
            <p class="p">深圳市微微定科技有限公司（以下简称微微定）是深圳市微微数码有限公司旗下子公司，成立于2017年，是中国产业互联网大定制综合电商平台。</p>
            <p class="p">微微定以产业互联网产品化大定制为中心目标，秉承“让定制更省心”的价值理念，率先制定行业标准，依托信息化工业相融合的供应链体系，通过大规模定制服务解决方案开创新制造及新零售背景下的个性化定制生产商业模式。</p>
            <p class="p">微微定为千万中小微企业与个人用户提供一站式营销用品定制服务，助力企业文化宣传，塑造企业品牌形象，降低企业营销成本。迄今为止，微微定已开发“餐饮服务用品、商务用品、企业宣传用品、广告营销用品、包装展示品”等众多类型的营销用品定制；其中，荷包纸巾、手帕纸巾、方盒纸巾、盒装抽纸、迷你手帕纸、散装纸、一次性纸杯、奶茶杯等自带广告引流和品牌传播特点的营销定制用品，深受用户青睐。</p>
            <p class="p">我们坚信再小的个体也有品牌传播需求，微微定将发挥自身优势，整合行业资源，集中化定制，小规模起订门槛，帮助中小微企业和个体用户快速成长。</p>
            <p class="p">我们将始终坚持聚焦大规模产品化定制主航道，按照以客户为中心，以同行者为本，虚心若愚，励精图治，致力于在定制领域做出我们应有的贡献。</p>
            <p class="p">微微定期待与您携手共创大定制时代。</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'meetwwdgroup2',
  props: {
    msg: String
  },
  data(){
    return {
    }
  },
  methods:{
  },
  mounted(){
  }
}
</script>
<style  lang="scss" scoped>
.meetwwdgroup2 {
  width: 100%;background: #fff;padding-top:180px;
  .head {
    width: 1200px;margin:0 auto;
    .h6 {
      position: relative;height: 150px;text-align:left;
      p {
        font-size: 32px;position: relative;z-index: 2;letter-spacing: 5px;margin-top:10px;
      }
      .sp {
        display: block;color:#dcdcdc;font-size: 42px;
      }
      .sp1 {
        display: block;border:26px solid #f7f7f7;width: 33px;height: 33px;border-radius: 50%;left:100px;top:55px;position: absolute;z-index: 1;
      }
    }
  }
  .center {
    width: 100%;overflow: hidden;margin-top:20px;margin-top:-120px;
    .box {
      width: 1200px;margin:0 auto;
      .bg {
        width: 1920px;height: 895px;background: url('../../assets/img/meetwwdgroup/group3.png') 0px 0px no-repeat;margin-left:-360px;position: relative;
        .img {
          position: absolute;right: 0px;top:0px;
        }
        .infos {
          width: 1200px;margin:0 auto;padding-top:170px;
          .p {
            width: 800px;font-size: 16px;line-height:32px;color:#666;
          }
        }
      }
    }
  }
}
</style>
