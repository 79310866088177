<!-- 认识微微定组4 -->
<template>
  <div class="meetwwdgroup4">
    <div class="head">
      <h6 class="h6">
        <span class="sp">COMPANY PROFILE</span>
        <p>企业文化</p>
      </h6>
    </div>
    <div class="center">
      <div class="box">
        <div class="bg">
          <div class="list">
            <div class="item">
              <svg><use xlink:href="#iconyuanjing"></use></svg>
              <h6 class="h6">愿景</h6>
              <p class="p">成为中国领先的大规模定制服务商</p>
            </div>
            <div class="item">
              <svg><use xlink:href="#icondingwei"></use></svg>
              <h6 class="h6">定位</h6>
              <p class="p">企业场景化营销品定制服务商</p>
            </div>
            <div class="item">
              <svg><use xlink:href="#iconjiazhiguan"></use></svg>
              <h6 class="h6">价值观</h6>
              <p class="p">以客户为中心，以奋斗者为本，正道直行，创新超越</p>
            </div>
            <div class="item">
              <svg><use xlink:href="#iconlinian"></use></svg>
              <h6 class="h6">理念</h6>
              <p class="p">为客户提供卓越的营销品定制服务</p>
            </div>
            <div class="item">
              <svg><use xlink:href="#iconyoushi"></use></svg>
              <h6 class="h6">优势</h6>
              <p class="p">科技赋能，闭环效率</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'meetwwdgroup4',
  props: {
    msg: String
  },
  data(){
    return {
    }
  },
  methods:{
  },
  mounted(){
  }
}
</script>
<style  lang="scss" scoped>
.meetwwdgroup4 {
  width: 100%;background: #fff;padding-top:100px;
  .head {
    width: 1200px;margin:0 auto;z-index: 9;
    .h6 {
      position: relative;
      p {
        font-size: 32px;position: relative;z-index: 2;letter-spacing: 5px;text-align: center;color:#fff;
        &:after {
          content: "";display: block;position: absolute;width: 60px;height: 5px;background: #fff;left:calc((100% - 60px)/2);bottom:-20px;
        }
      }
      .sp {
        display: block;color:#dcdcdc;font-size: 42px;text-align: center;position: relative;z-index: 5;opacity: 0.6;
      }
    }
  }
  .center {
    width: 100%;overflow: hidden;margin-top:20px;margin-top:-120px;
    .box {
      width: 1200px;margin:0 auto;
      .bg {
        background: url('../../assets/img/meetwwdgroup/group9.png') 0px 0px no-repeat;
        width: 1920px;height: 560px;margin-left:-360px;position: relative;
        .list {
          width: 1200px;margin:0 auto;zoom:1;padding-top:240px;
          .item {
            width: 200px;float:left;margin-right: 50px;text-align: center;
            svg  {
              width: 80px;height: 80px;fill:#fff;
            }
            .h6 {
              display: block;color:#fff;font-size: 24px;font-weight: normal;margin-top:50px;
            }
            .p {
              color:#fff;font-size: 14px;margin-top:15px;
            }
            &:last-child {
              margin-right: 0px;
            }
          }
          &:after {
            content: '';display: block;clear:both;
          }
        }
      }
    }
  }
}
</style>
