<!-- 认识微微定头部轮播 -->
<template>
  <div class="meetwwdcarousel">
    <div class="heads">
      <img :src="this.$store.state.logoUrl1" @click='linklogo'>
      <hotsearch class='search'></hotsearch>
    </div>
    <div class="info">
      <h6 class="h6">
        微微定
        <span>WWDING</span>
      </h6>
      <p class="p">互联网营销用品定制平台</p>
      <p class="p1">助力企业文化<span></span>凸显品牌价值</p>
      <p class="p2">微微定·大定制·省心定</p>
    </div>
    <div class="imgbox">
      <img :class='{choiceimg:chioce==index}' :src="item.url" v-for='item,index in imgurl' :key='index'>
      <div v-if='imgurl.length>1' class="menu" :style='{width:imgurl.length*60+(imgurl.length-1)*10+"px",left:(1200 - (imgurl.length*60+(imgurl.length-1)*10))/2+"px"}'>
        <button :class='{choice:chioce==index}' @click='choice(index)' v-for='item,index in imgurl' :key='index'></button>
      </div>
    </div>
  </div>
</template>
<script>
import hotsearch from '@/components/hotsearch/hotsearch.vue';//带热门搜索组件
export default {
  name: 'meetwwdcarousel',
  components:{
    hotsearch,
  },
  data(){
    return {
      imgurl:[{url:require('../../assets/img/meetwwdcarousel/bg.png')}],
      chioce:0,
    }
  },
  props: {
    msg: String
  },
  methods:{
    linklogo(){
      // logo跳转
      if(this.$store.state.logoUrl){
        window.location.href=this.$store.state.logoUrl
      }
    },
    choice(v){
      // 切换
      this.chioce = v
    }
  },
  mounted(){
  }
}
</script>
<style  lang="scss" scoped>
.meetwwdcarousel {
  width: 100%;overflow: hidden;position: relative;
  .heads {
    width: 1200px;height: 80px;margin:0 auto;zoom:1;position: absolute;top:0px;left:calc((100vw - 1200px)/2);z-index: 9;
    img {
      display: block;float:left;margin-top:30px;cursor: pointer;
    }
    .search {
      float:right;margin-top:35px;
      /deep/ input {
        background: rgba(255,255,255,0.2);border:0px;
      }
      /deep/ .hots {
        button {
          color:#fff;
        }
      }
      /deep/ .sh {
        svg {
          fill: #fff;
        }
      }
    }
    &:after {
      content: '';display: block;clear:both;
    }
  }
  .info {
    position: absolute;width: 520px;height: 470px;left:calc((100vw - 520px)/2);top:290px;z-index: 9;
    .h6 {
      font-size: 72px;color:#fff;text-align: center;font-weight: normal;
      span {
        display: block;font-size: 14px;margin-top:5px;position: relative;
        &:after {
          content: '';display: block;position: absolute;width: 90px;height: 5px;background: #fff;left:calc((100% - 90px)/2);bottom:-20px;
        }
      }
    }
    .p {
      text-align: center;margin-top:90px;color:#fff;font-size: 20px;
    }
    .p1 {
      text-align: center;color:#fff;font-size: 26px;margin-top:15px;
      span {
        padding:0 10px;
      }
    }
    .p2 {
      text-align: center;color:#fff;font-size:20px;border:1px solid #fff;width: 220px;height: 40px;margin:0 auto;margin-top:60px;line-height: 40px;
    }
  }
  @media screen and (max-width: 1200px) { //布局容器宽度自适应<1200宽度的时候固定为1200
    .heads   {
      left:calc((1200px - 1200px)/2);
    }
  }
  .imgbox {
    width: 1200px;margin:0 auto;height: 919px;zoom:1;position: relative;
    img {
      left:calc((1200px  - 1920px)/2);float:left;top:0px;position: absolute;z-index: 8;opacity: 0;transition: opacity 1s;
    }
    .choiceimg {
      opacity: 1;
    }
    .menu {
      position: absolute;bottom:50px;z-index: 9;
      button {
        width: 60px;height: 15px;border:1px solid #fff;background: none;margin-right: 10px;
        &:last-child {
          margin-right: 0px;
        }
      }
      .choice {
        background: #fff;
      }
    }
  }
}
</style>
