<!-- 认识微微定组5 -->
<template>
  <div class="meetwwdgroup5">
    <div class="head">
      <h6 class="h6">
        <span class="sp">DEVELOPMENT HISTORY</span>
        <p class="p">2019-2022年战略目标</p>
        <p class="p1">微微定是印刷科学领域的全球领先的创新企业，利用印刷领域的专业知识，
开发出了产品，开创了新的行业，也改变了人类生活。</p>
      </h6>
    </div>
    <div class="center">
      <div class="box">
        <div class="bg">
          <div class="timeline">
            <span class="lt"><svg><use xlink:href="#iconziyuan2"></use></svg></span>
            <span class="rt"><svg><use xlink:href="#iconziyuan4"></use></svg></span>
            <!-- 3 -->
            <!-- <span class="sp"></span>
            <span class="sp"></span>
            <span class="sp"></span> -->
            <span class="sp"></span>
            <span class="sp"></span>
            <span class="sp"></span>
            <span class="sp"></span>
            <button class="btn">2020 Now</button>
            <!-- 11 -->
           <!--  <p class="p"><svg><use xlink:href="#iconxiangxiaxialakuang"></use></svg><span class="ps">2007年</span><span class="ps">完成了公司的第一次转折</span></p>
            <p class="p"><svg><use xlink:href="#iconxiangshangxialakuang"></use></svg><span class="ps">2008年</span><span class="ps">完成了公司的第一次转折</span></p>
            <p class="p"><svg><use xlink:href="#iconxiangxiaxialakuang"></use></svg><span class="ps">2009年</span><span class="ps">完成了公司的第一次转折</span></p> -->
            <p class="p"><svg><use xlink:href="#iconxiangshangxialakuang"></use></svg><span class="ps">销售额突破15亿元</span><span class="ps"></span></p>
            <p class="p"><svg><use xlink:href="#iconxiangxiaxialakuang"></use></svg><span class="ps">利润突破1亿元</span><span class="ps"></span></p>
            <p class="p"><svg><use xlink:href="#iconxiangshangxialakuang"></use></svg><span class="ps">打造富有竞争力的产品布局</span><span class="ps"></span></p>
            <p class="p"><svg><use xlink:href="#iconxiangxiaxialakuang"></use></svg><span class="ps">拥有领先同行的运营水平</span><span class="ps"></span></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'meetwwdgroup5',
  props: {
    msg: String
  },
  data(){
    return {
    }
  },
  methods:{
  },
  mounted(){
  }
}
</script>
<style  lang="scss" scoped>
.meetwwdgroup5 {
  width: 100%;background: #fff;padding-top:50px;
  .head {
    width: 1200px;margin:0 auto;z-index: 9;
    .h6 {
      position: relative;height: 40px;
      .p {
        font-size: 32px;position: relative;z-index: 2;letter-spacing: 5px;text-align: center;color:#333;
        &:after {
          content: "";display: block;position: absolute;width: 60px;height: 5px;background: #b3b3b3;left:calc((100% - 60px)/2);bottom:-20px;
        }
      }
      .sp {
        display: block;color:#333;font-size: 42px;text-align: center;position: relative;z-index: 5;
      }
      .p1 {
        position: absolute;top:170px;color:#333;z-index: 5;width: 600px;left:calc((100% - 600px)/2);text-align: center;font-size: 14px;
      }
    }
  }
  .center {
    width: 100%;overflow: hidden;margin-top:20px;margin-top:-120px;
    .box {
      width: 1200px;margin:0 auto;
      .bg {
        background: url('../../assets/img/meetwwdgroup/group10.png') 0px 0px no-repeat;
        width: 1920px;height: 904px;margin-left:-360px;position: relative;
        .timeline {
          width: 835px;height: 50px;margin:0 auto;padding-top:540px;position: relative;
          .lt {
            width: 30px;height: 30px;border:2px solid #ffa200;display: block;border-radius: 50%;text-align: center;float:left;margin-top:8px;
            svg {
              width: 20px;height: 20px;vertical-align: -10px;fill: #ffa200;
            }
          }
          .rt {
            width: 30px;height: 30px;border:2px solid #ffa200;display: block;border-radius: 50%;text-align: center;float:right;margin-top:8px;
            svg {
              width: 20px;height: 20px;vertical-align: -10px;fill: #ffa200;
            }
          }
          .sp {
            width: 8px;height: 8px;background: #fff;border-radius: 50%;border:3px solid #ffa200;display: block;position: absolute;z-index: 5;bottom:18px;
            // &:nth-child(3) {
            //   left:120px;
            // }
            // &:nth-child(4) {
            //   left:278px;
            // }
            // &:nth-child(5) {
            //   left:434px;
            // }
            &:nth-child(3) {
              left:192px;
            }
            &:nth-child(4) {
              left:350px;
            }
            &:nth-child(5) {
              left:500px;
            }
            &:nth-child(6) {
              left:660px;border:3px solid #ff1414;
              &:after {
                content: '';display: block;position: absolute;width: 14px;height: 14px;border:6px solid rgba(255,20,20,0.3);border-radius: 50%;left:-9px;top:-9px;
              }
              &:before {
                content: '';display: block;position: absolute;width: 25px;height: 25px;border:6px solid rgba(255,20,20,0.1);border-radius: 50%;left:-14px;top:-14px;
              }
            }
          }
          .btn {
            color:#ffa200;width: 128px;height: 36px;border:2px solid #ffa200;border-radius: 20px;background: none;font-size: 18px;position: absolute;bottom:-250px;left:calc((100% - 128px)/2);
          }
          .p {
            width: 148px;height: 76px;background: #ffa200;border-radius: 5px;position: absolute;
            .ps {
              color:#fff;font-size: 14px;padding:0 10px;display: block;
              &:nth-child(2) {
                font-size: 20px;margin-bottom:5px;
              }
            }
            svg {
              fill: #ffa200;width: 25px;height: 25px;position: absolute;bottom:-17px;left:calc((100% - 25px)/2);
            }
            // &:nth-child(11) {
            //   bottom:70px;left:55px;
            // }
            // &:nth-child(12) {
            //   bottom:-100px;left:211px;
            //   svg {
            //     bottom:68px;
            //   }
            // }
            // &:nth-child(13) {
            //   bottom:70px;left:365px;
            // }
            &:nth-child(8) {
              bottom:-100px;left:125px;
              .ps {
                margin-top:12px;text-align: center;
              }
              svg {
                bottom:68px;
              }
            }
            &:nth-child(9) {
              bottom:70px;left:280px;
              .ps {
                margin-top:12px;text-align: center;
              }
            }
            &:nth-child(10) {
              bottom:-100px;left:435px;
              .ps {
                margin-top:12px;text-align: center;
              }
              svg {
                bottom:68px;
              }
            }
            &:nth-child(11) {
              bottom:70px;left:595px;background: #ff1414;
              .ps {
                margin-top:12px;text-align: center;
              }
              svg {
                fill: #ff1414;
              }
            }
          }
          &:after {
            content: '';display: block;width: 740px;height:5px;background: #ffa200;border-radius: 10px;left:48px;bottom:23px;position: absolute;
          }
        }
      }
    }
  }
}
</style>
